export const links = [
  {
    name: "ПРОДУКЦИЯ",
    url: '/product'
  },
  {
    name: "ГАРАНТИЯ КАЧЕСТВА",
    url: '/quality'
  },
  {
    name: "О Компании",
    url: '/info'
  },
  {
    name: "Отзывы",
    url: '/comment'
  },
]


