<template>
  <footer class="footer">
    <div class="container footer__content">
      <div class="footer__content-left">
        <div class="footer__content-block">
          <h6 class="footer__content-title">Fishtime Uzbekistan</h6>
          <p class="footer__content-text">
            Toshkent viloyati, Yuqorichirchiq tumani, Yangibozor MFY
          </p>
          <p class="footer__content-text">
            tel:
            <a class="footer__content-link" href="tel:+998950055550"
              >+998 95 005 55 50</a
            >
          </p>
        </div>
        <div class="footer__content-block">
          <h6 class="footer__content-title">Oтправить резюме</h6>
          <a class="footer__content-link" href="mailto:praca@aller-aqua.pl"
            >praca@aller-aqua.pl</a
          >
        </div>
      </div>
      <ul class="footer__content-block footer__list">
        <li>
          <a href="#!" class="footer__content-link">
            <img src="@/assets/images/social/facebook.svg" alt="" />
          </a>
        </li>
        <li>
          <a href="https://instagram.com/fishtime.uz?igshid=c3l6YmJ3cTVzam56&utm_source=qr" class="footer__content-link">
            <img src="@/assets/images/social/instagram.svg" alt="" />
          </a>
        </li>
        <li>
          <a href="#!" class="footer__content-link">
  </a>
        </li>
        <li>
          <a href="#!" class="footer__content-link">
            <img src="@/assets/images/social/youtube.svg" alt="" />
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer-vue",
};
</script>

<style>
</style>