<template>
  <h3 class="aller-logo">birgalikda rivojlanamiz</h3>
</template>

<script>
export default {
  name: 'Logo-vue'
}
</script>

<style>

</style>